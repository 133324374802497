.header {
  color: white;
  /* background-color: black; */
  display: flex;
  padding: 20px;
  align-items: center;
  justify-content: space-between;
  overflow-x: scroll;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.header::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.header {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.header__icons {
  display: flex;
  align-items: center;
  text-align: center;
}

.header__icon:hover > p {
  display: inline;
}

.header__icon--active > p {
  display: inline !important;
}

.header__icon > p {
  text-transform: uppercase;
  letter-spacing: 3px;
  margin-left: auto;
  margin-right: auto;
  font-size: x-small;
  display: none;
}

.header__icon > .MuiSvgIcon-root {
  margin-left: auto;
  margin-right: auto;
  font-size: 30px;
}

.header__icon {
  display: flex;
  flex-direction: column;
  width: 100px;
  cursor: pointer;
}

.header > div > img {
  object-fit: contain;
  height: 80px;
}

.author {
  display: flex;
}

.author > h4 {
  padding-top: 10%;
}

.author > h4 > a {
  text-decoration: none;
  color: white;
}

.author > h4 > a:hover {
  text-decoration: underline;
  color: white;
}
