.nav {
  color: white;
  display: flex;
  padding-left: 20px;
  padding-right: 0px;
  overflow-x: scroll;
  overflow-y: visible;
  word-wrap: normal;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.nav::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.nav {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.nav > h2 {
  font-size: medium;

  margin-left: 25px;
  margin-right: 25px;
  cursor: pointer;
  opacity: 0.7;
  transition: trasnform 50ms ease-in;
}

.nav > h2:hover {
  opacity: 1;
  transform: scale(1.2);
}
@media only screen and (min-width: 700px) {
  .nav {
    padding-bottom: 25px;
  }
}
